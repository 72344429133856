import React from "react";
import PropTypes from 'prop-types';

class BlockImageRight extends React.Component {
//   constructor(props) {
//     super(props);
//   }

  render() {
    return (
        
        <div className="bg-gray-200">
            <div className="max-w-7xl mx-auto py-8 lg:flex lg:flex-row-reverse">
                
                {/* RIGHT */}
                <div className="h-64 w-full sm:h-96 lg:w-1/2" >
                    {this.props.children}
                    {/* <video autoPlay muted loop playsInline preload="auto" muted width="100%" className="lg:max-h-96 ">
                        <source src={sharevideo} type="video/mp4" />
                    </video> */}
                    {/* <img className="max-h-80" src={winterfacelogo} alt="winterface" /> */}
                </div>

                {/* LEFT */}
                <div className="h-full w-full lg:h-96 lg:w-1/2">
                    <div className="mt-4 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 lg:max-w-xl lg:mx-0 lg:mr-6 xl:max-w-2xl">
                        <div className="text-center lg:text-left">
                            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl lg:pr-8">
                                {this.props.title}
                            </h2>
                            <p className="mt-3 text-lg text-gray-500 sm:mt-5 sm:text-xl sm:mx-auto md:mt-5 md:text-2xl ">
                                {this.props.desc}
                            </p>
                            {
                                this.props.desc2
                                ? 
                                    <p className="mt-3 text-lg text-gray-500 sm:mt-5 sm:text-xl sm:mx-auto md:mt-5 md:text-2xl ">
                                        {this.props.desc2}
                                    </p>
                                : null
                            }
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    );
  }
}

export default BlockImageRight;


BlockImageRight.propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    desc2: PropTypes.string,
};
import React from "react";
// import PropTypes from 'prop-types';

// custom components
import BlockImageLeft from "./BlockImageLeft";
import BlockImageRight from "./BlockImageRight";

// videos
import offlineOnline from "../assets/offline_online.png";
import refData from "../assets/ref_data.png";
import targets from "../assets/targets.png";

class Uses extends React.Component {


    render() {
        
        return (
            <div className="pt-6 bg-gray-900 ">
                <div className="max-w-4xl mx-auto ">
                    <div className="text-center text-3xl tracking-tight font-extrabold text-gray-200 sm:text-5xl md:text-6xl">
                        Uses
                    </div>
                    <div className="mb-8 px-2 text-center text-gray-200 text-lg sm:text-xl md:text-2xl">
                        Just some of the ways Winterface simplifies managing data.
                    </div>
                </div>
                <div>

                    <BlockImageLeft 
                        title="Load offline data"
                        desc="Whether it's data from vendors, customers, field staff, or offline analysis in Excel, 
                                Winterface makes it easy to get that offline data into your Snowflake instance."
                        desc2="You can accomplish this by using the file upload features or providing access to the simple spreadsheet interface."
                    >
                        <img className="max-h-64 sm:max-h-96 mx-auto" src={offlineOnline} alt="targets" />
                    </BlockImageLeft>

                    <BlockImageRight
                        title="Manage targets"
                        desc="Targets help provide context to your data and provide a reference to the progress being made."
                        desc2="In Winterface, adding and updating targets takes only seconds and can accomodate if they are driver-based, thresholds, or exact targets."
                    >
                        <img className="max-h-64 sm:max-h-96 mx-auto" src={targets} alt="targets" />
                    </BlockImageRight>

                    <BlockImageLeft 
                        title="Manage reference data"
                        desc="There are a lot of great solutions to get your systems' data into Snowflake.
                            However, to get data from your people, you need to write insert/update statements or scripts to source data from spreadsheets."
                        desc2="With Winterface, you enable your entire team to participate in managing data like mappings, definitions, or standards."
                    >
                        <img className="max-h-64 sm:max-h-96 mx-auto" src={refData} alt="targets" />
                    </BlockImageLeft>

                </div>
            </div>
        );
    }
}

export default Uses;


// Uses.propTypes = {
//    adverb: PropTypes.string.isRequired,
//    verb: PropTypes.string.isRequired,
//    tail: PropTypes.string.isRequired,
//    desc: PropTypes.string.isRequired,
//    desc2: PropTypes.string,
// };
import React from "react";
import winterface_hero from "../assets/winterface_hero.png";

import NavBar from "./Navbar";
// import Footer from "./Footer";
import Accordion from "./Accordion";
import Advantages from "./Advantages";
import Uses from "./Uses";

class LandingPage extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div className="relative bg-white overflow-hidden">
                <NavBar />

                <div className="relative pt-6 px-4 h-20 sm:px-6 lg:px-8 lg:pb-4"></div>

                <div className="max-w-7xl mx-auto h-auto">
                    
                    {/* TOP */}
                    <div className="flex justify-center -mt-36 " >
                        {/* <video autoPlay muted loop playsInline preload="auto" width="100%">
                            <source src={headervideo} type="video/mp4" />
                        </video> */}
                        <img className="mx-auto max-w-none object-cover" style={{width:700, height:700}} src={winterface_hero} alt="winterface" />
                    </div>

                    {/* BOTTOM */}
                    <div className="bg-white relative pb-8 -mt-48 mx-auto max-w-3xl md:pb-12 ">
                        
                        {/* Main Content */}
                        <main className="mt-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="text-center">
                                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl ">
                                    <span className="block inline">
                                        A simple spreadsheet interface for {" "}
                                    </span>
                                    <span className="block text-wblue inline ">
                                        Snowflake
                                    </span>
                                    <span className="block inline">
                                        {" "}
                                         
                                    </span>
                                </h1>
                                <p className="mt-3 text-lg text-gray-500 sm:mt-5 sm:text-xl sm:mx-auto md:mt-5 md:text-2xl ">
                                    Manage data in Snowflake Data Cloud as if it were a spreadsheet (including uploading your existing spreadsheets).
                                </p>
                                {/* <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
                                    <div className="rounded-md shadow">
                                        <a
                                            href="/pricing"
                                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-wblue-dark hover:bg-wblue md:py-4 md:text-lg md:px-10"
                                        >
                                            Get started
                                        </a>
                                    </div>
                                    <div className="mt-3 sm:mt-0 sm:ml-3">
                                        <a
                                            href="/contact"
                                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-wblue-dark bg-wblue-light hover:bg-wblue md:py-4 md:text-lg md:px-10"
                                        >
                                            Live demo
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </main>
                    </div>
                    
                </div>

                <Uses />
                <Accordion />
                <Advantages />
                 


                
                <div className="py-8 bg-white">
                        
                    <div className="max-w-7xl mx-auto">

                        {/* LEFT */}
                        <div className="flex-grow relative max-h-96">
                            <div className="mt-10 mx-auto max-w-7xl px-4">
                                <div className="text-center">
                                    <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl lg:pr-8">
                                        <span className="block inline">
                                            Ready to interface?
                                        </span>
                                    </h2>
                                    {/* href="/pricing" */}
                                    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
                                        <div className="rounded-md shadow">
                                            <a
                                                href="https://share.hsforms.com/1BP2Hla-eQzCT0XbsCK-fTg5m24a"  
                                                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-wblue-dark hover:bg-wblue md:py-4 md:text-lg md:px-10"
                                            >
                                                Get started for free
                                            </a>
                                        </div>
                                    </div>
                                    <div className="sm:flex sm:justify-center">
                                        <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl ">
                                            All you need is a Snowflake instance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
                

                {/* <Footer /> */}
            </div>
        );
    }
}

export default LandingPage;

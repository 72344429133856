import React from "react";
import PropTypes from 'prop-types';

class AccordionExpanded extends React.Component {
//   constructor(props) {
//         super(props);
//   }

  render() {
    return (
        <article className="border-b">
            <div className="border-l-2 bg-grey-lightest border-wblue">
                <header className="flex justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none" onClick={()=>{this.props.onClick()}}>
                    <div className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl lg:pr-8">
                        <span className="block inline">
                             {this.props.adverb} {" "}
                        </span>
                        <span className="block text-wblue inline">
                            {this.props.verb} 
                        </span>
                        <span className="block inline">
                            {" "} {this.props.tail} 
                        </span>
                    </div>
                    <div className="rounded-full border border border-wblue w-7 h-7 flex items-center justify-center bg-wblue">
                        <svg aria-hidden="true" data-reactid="281" fill="none" height="24" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewbox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                            <polyline points="18 15 12 9 6 15">
                            </polyline>
                        </svg>
                    </div>
                </header>
                <div className="px-4 pb-4 text-center ">
                    {this.props.children}
                    <p className="mt-3 text-lg text-gray-800 sm:mt-5 sm:text-xl sm:mx-auto md:mt-5 md:text-2xl ">
                        {this.props.desc}
                    </p>
                    {
                        this.props.desc2
                        ? 
                            <p className="mt-3 text-lg text-gray-800 sm:mt-5 sm:text-xl sm:mx-auto md:mt-5 md:text-2xl ">
                                {this.props.desc2}
                            </p>
                        : null
                    }
                </div>
            </div>
        </article>
    );
  }
}

export default AccordionExpanded;


AccordionExpanded.propTypes = {
   adverb: PropTypes.string.isRequired,
   verb: PropTypes.string.isRequired,
   tail: PropTypes.string.isRequired,
   desc: PropTypes.string.isRequired,
   desc2: PropTypes.string,
};
import React from "react";
import PropTypes from 'prop-types';

class AccordionCollapsed extends React.Component {
//   constructor(props) {
//         super(props);
//   }

  render() {
    return (
        <article className="border-b">
            <div className="border-l-2 border-transparent">
                <header className="flex justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none" onClick={()=>{this.props.onClick()}}>
                    <div className="text-2xl tracking-tight font-extrabold text-gray-500 sm:text-4xl md:text-5xl lg:pr-8">
                        <span className="block inline">
                            {this.props.adverb} {" "}
                        </span>
                        <span className="block text-wblue inline">
                            {this.props.verb}
                        </span>
                        <span className="block inline">
                            {" "} {this.props.tail} 
                        </span>
                    </div>
                    <div className="rounded-full border border-grey w-7 h-7 flex items-center justify-center">
                        <svg aria-hidden="true" className="" data-reactid="266" fill="none" height="24" stroke="#606F7B" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewbox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                            <polyline points="6 9 12 15 18 9">
                            </polyline>
                        </svg>
                    </div>
                </header>
                <div className="hidden">
                    {this.props.desc}
                </div>
            </div>
        </article>
    );
  }
}

export default AccordionCollapsed;


AccordionCollapsed.propTypes = {
   adverb: PropTypes.string.isRequired,
   verb: PropTypes.string.isRequired,
   tail: PropTypes.string.isRequired,
   desc: PropTypes.string.isRequired,
   desc2: PropTypes.string,
};
import React from "react";
import PropTypes from 'prop-types';

import AccordionCollapsed from "./AccordionCollapsed";
import AccordionExpanded from "./AccordionExpanded";

class AccordionItem extends React.Component {
//   constructor(props) {
//         super(props);
//   }

  

  render() {
      let content = 
        this.props.expanded 
        ?   <AccordionExpanded {...this.props} /> 
        :   <AccordionCollapsed {...this.props} />
        

    return (content);
  }
}

export default AccordionItem;


AccordionItem.propTypes = {
    expanded: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    adverb: PropTypes.string.isRequired,
    verb: PropTypes.string.isRequired,
    tail: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    desc2: PropTypes.string,
};
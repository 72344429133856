import React from "react";
// import PropTypes from 'prop-types';

class Advantages extends React.Component {
  

    render() {
        return (
            <div className="py-6 bg-gray-900">
                <div className="max-w-4xl mx-auto ">
                    <div className="text-center text-3xl tracking-tight font-extrabold text-gray-200 sm:text-5xl md:text-6xl">
                        Advantages
                    </div>
                    <div className="mb-8 px-2 text-center text-gray-200 text-lg sm:text-xl md:text-2xl">
                        Instantly improve your Snowflake experience in these areas.
                    </div>
                    <div className="flex flex-wrap w-full" >
                        <div className="m-2 flex-1">
                            <div
                                className=" px-8 py-8 bg-white h-full rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                            >
                                {/* <img src="https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/152_Google_Play-512.png" className="logo-area h-4" /> */}
                                <h3 className="py-2 text-4xl font-extrabold text-center font-mono">PARTICIPATION</h3>
                                <p className="">Engage users with a broad range of skillsets in managing your Snowflake data by providing easy-to-use tools.</p>
                            </div>
                        </div>
                        <div className="m-2 flex-1">
                            <div
                                className=" px-8 py-8 bg-white h-full rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                            >
                                <h3 className="py-2 text-4xl font-extrabold text-center font-mono">COLLABORATION</h3>
                                <p className="">By working in the same system, engineering and the business can work in parallel rather than a never-ending series of handoffs.</p>
                            </div>
                        </div>
                        <div className="m-2 flex-1">
                            <div
                                className=" px-8 py-8 bg-white h-full rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                            >
                                <h3 className="py-2 text-4xl font-extrabold text-center font-mono">ITERATION</h3>
                                <p className="">Quickly adjust drivers, targets, and mappings.  Perform "what-if" analysis directly in Snowflake.</p>
                            </div>
                        </div>
                        <div className="m-2 flex-1">
                            <div
                                className=" px-8 py-8 bg-white h-full rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                            >
                                <h3 className="py-2 text-4xl font-extrabold text-center font-mono">GOVERNANCE</h3>
                                <p className="">All of your data is centralized, now your processes can be centralized too. With audit logs, it's easy to keep track of changes.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Advantages;


// Advantages.propTypes = {
//    adverb: PropTypes.string.isRequired,
//    verb: PropTypes.string.isRequired,
//    tail: PropTypes.string.isRequired,
//    desc: PropTypes.string.isRequired,
//    desc2: PropTypes.string,
// };
import React from "react";
import PropTypes from 'prop-types';
import fulllogo from "../assets/fulllogo.svg";

class NavBarMobile extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    let topClass = "absolute top-0 inset-x-0 p-2 z-20 transition transform origin-top-right md:hidden"
    topClass = this.props.hidden ? "hidden " + topClass : topClass
    return (
      <div className={topClass}>
        <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="px-2 pt-4 flex items-center justify-between">
            <div>
              <img
                className="h-8 w-auto"
                src={fulllogo}
                alt="winterface.io"
              />
            </div>
            <div className="-mr-2">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={this.props.toggleMenu}
              >
                <span className="sr-only">Close main menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="main-menu"
          >
            <div className="px-2 pt-2 pb-3 space-y-1" role="none">
              <a
                href="/"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                role="menuitem"
              >
                Home
              </a>

              {/* <a
                href="/blog"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                role="menuitem"
              >
                Blog
              </a>

              <a
                href="/pricing"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                role="menuitem"
              >
                Pricing
              </a>*/}

              <a
                href="/docs"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                role="menuitem"
              >
                Docs
              </a> 

              <a
                href="https://share.hsforms.com/1BP2Hla-eQzCT0XbsCK-fTg5m24a"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                role="menuitem"
              >
                Contact
              </a>
            </div>
            {/* <div role="none">
              <a
                href="/login"
                className="block w-full px-5 py-3 text-center font-medium text-wblue bg-gray-50 hover:bg-gray-100"
                role="menuitem"
              >
                Log in
              </a>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default NavBarMobile;


NavBarMobile.propTypes = {
  hidden: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};